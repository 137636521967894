.servicesContentMain {
    display: flex;
    padding: 60px 0px;
    /* float: left; */
}

.servicesContentLeft {
    width: 50%;
}

.servicesContentLeft img {
    width: 100%;
    height: 100%;
}

.servicesContentRight {
    width: 50%;
    padding: 80px 30px 80px 60px;
    background-color: #204379;
    color: white;
    
} 

.servicesContentRight h2 {
    font-size: 45px;
}

.servicesContentRight p {
    width: 85%;
    font-size: 28px;
    padding-top: 20px;
    text-align: justify;
}
@media screen and (max-width: 1024px) {
    .servicesContentRight {
       
        padding: 80px 30px 80px 30px;
       
    } 
    
    .servicesContentRight h2 {
        font-size: 40px;
    }
    
    .servicesContentRight p {
        width: 85%;
        font-size: 22px;
        padding-top: 20px;
        text-align: justify;
    }
  }
  @media screen and (max-width: 425px) {
    .servicesContentLeft {
        width: 20%;
        height:200px;
        float: left;
        display: none;
    }
    
    .servicesContentLeft img {
        width: 100%;
        height: 100%;
    }
    
    .servicesContentRight {
        width: 100%;
      
        
    } 
    .servicesContentRight h2 {
        font-size: 29px;
    }
    
    .servicesContentRight p {
        width: 93%;
        font-size: 16px;
        padding-top: 20px;
        text-align: justify;
    }
    .servicesContentRight {
       
        padding: 60px 30px 60px 30px;
       
    } 
  }