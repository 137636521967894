.choose-us {
  display: flex;
  width: 70%;
  /* justify-content: space-between; */
  /* border: 1px solid green; */
  margin: 0 auto;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
}

.choose-us_info {
  width: 60%;
  /* border: 1px solid red; */
}
.choose-us_info p {
  color: var(--grayBack);
  padding: 0;
}

.chooseHeading {
	font-size: 33px;
	padding-bottom: 20px;
}

.choose-us_img {
  display: flex;
  justify-content: center;
  width: 42%;
  padding-left: 35px;
  /* border: 1px solid green; */
  /* padding: 1rem; */
}

/* home services */
.home-services {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  padding-top: 1rem;
}

.home-services-items {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
  /* border: 1px solid red; */
}

.home-services-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  padding: 1.8rem;
  border-radius: 50%;
  background-color: var(--primaryColor);
  
}

.home-services-logo:hover {
	background-color: var(--mainColor);
}

.home-services-logo:hover a {
	color: white;
}

.home-services-logo a {
	color: white;
}


/* #204379 */

.home-services-logo ~ div {
  width: 75%;
}

.home-services-items h3 {
	padding-left: 20px;
	color: var(--primaryColor);
}

.home-services-items p {
  color: var(--grayBack);
  text-align: justify;
  line-height: 1.5;
  padding-left: 20px;
}

@media only screen and (max-width: 996px) {
  .choose-us {
    flex-direction: column;
  }
  .choose-us_info,
  .choose-us_img {
    width: 100%;
  }
  .home-services-items p  {
	padding-left: 0;
  }
  .home-services-items h3 {
	padding-left: 0px;
}
}

@media only screen and (max-width: 768px) {
  .home-services {
    grid-template-columns: auto;
  }
  .home-services-items p  {
	padding-left: 0;
  }
  .home-services-items h3 {
	padding-left: 0px;
}
.home-services-logo{
  height: 60px;
  width: 60px;
}
}
@media only screen and (max-width: 425px) {
  .home-services {
    grid-template-columns: auto;
  }
  .home-services-items p  {
	padding-left: 0;
  }
  .home-services-items h3 {
	padding-left: 0px;
}
.home-services-logo{
  height: 20px;
  width: 20px
}
}
