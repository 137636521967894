.gallery-lightbox {
  position: relative;
  z-index: 1001;
}

.container {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  padding: 5px;
}

.container .card {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 25px;
  height: 300px;
  overflow: hidden;
  width:90%;
  border-radius: 25px;

}
.container .card:hover {
  cursor: pointer;
  box-shadow:  0px 0px 10px 4px #204379;
}
.container .card img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.container .card .card--info {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease;
}

.container .card .card--info h1 {
  color: #fff;
  font-size: 2em;
  text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000;
}

.container .card .card--info label {
  color: var(--mainColor);
  font-size: medium;
  margin-top: 20px;
  text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000;
}

.container .card .card--info button {
  margin-top: 10px;
  border: none;
  /* outline: none; */
  /* background: var(--mainColor); */
  color: #fff;
  font-size: 18px;
  /* text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000; */
  /* width: 80px; */
  /* height: 25px; */
  border-radius: 5px;
  background-color: var(--primaryColor);
  padding: 7px 12px;
}

.card--info button {
  cursor: pointer;
}

.container .card .card--info button:hover {
  background-color: var(--mainColor);
}

.container .card .card--info h1,
.container .card .card--info label,
.container .card .card--info button {
  transform: translateX(-400px);
  transition: 0.5s ease;
}

.container .card:hover .card--info {
  opacity: 1;
}

.container .card:hover .card--info h1,
.container .card:hover .card--info label,
.container .card:hover .card--info button {
  transform: translateX(0px);
  transition: 0.5s ease;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  min-height: 100%;
  width: 100%;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);
  display: none;
}

.lightbox .carousel {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  z-index: 6;
}

.lightbox .carousel:hover {
  background: rgba(255, 0, 0, 0.7);
}

.lightbox .carousel:hover span {
  background: #fff;
}

.lightbox .carousel span {
  width: 10px;
  height: 3px;
  position: absolute;
  left: calc(50% - 5px);
  top: calc(50% - 1.5px);
  display: block;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  /* z-index: -1; */
}

.lightbox .carousel.left {
  left: 5px;
}

.lightbox .carousel.right {
  right: 0;
  right: 5px;
}

.lightbox .carousel.left span:nth-child(1) {
  transform: rotate(-30deg);
  transform-origin: left;
  /* left: 5px; */
}

.lightbox .carousel.left span:nth-child(2) {
  transform: rotate(30deg);
  transform-origin: left;
}

.lightbox .carousel.right span:nth-child(1) {
  transform: rotate(-30deg);
  transform-origin: right;
  right: 5px;
}

.lightbox .carousel.right span:nth-child(2) {
  transform: rotate(30deg);
  transform-origin: right;
}

.lightbox .close {
  background: rgb(255, 255, 255, 0.5);
  height: 20px;
  width: 60px;
  text-align: center;
  margin-top: 100px;
  border-radius: 20px;
  line-height: 20px;
  color: #000;
  transition: 0.5s;
  z-index: 6;
  cursor: pointer;
}

.lightbox .close:hover {
  background: rgba(255, 0, 0, 0.7);
  color: #fff;
}

.lightbox img {
  position: absolute;
  width: 70%;
  height: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  border: 1px solid white;
}

@media only screen and (max-width: 864px) {
  .container {
    grid-template-columns: auto auto;
    /* border: 1px solid green; */
  }
  .lightbox img {
    width: 70%;
    height: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    grid-template-columns: auto;
  }
  .lightbox img {
    width: 70%;
    height: 25%;
  }
  .container .card {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 25px;
    height: 300px;
    overflow: hidden;
    width:90%;
    border-radius: 25px;
    /* width: calc(30% - 7px); */
  }
}

/* @media only screen and (max-width: 1024px) {
  .container .card {
    width: 45%;
  }
  .lightbox img {
    width: 70%;
    height: 50%;
  }
}

/* @media only screen and (max-width: 900px) {
  .container .card {
    width: 40%;
  }
  .lightbox img {
    width: 70%;
    height: 45%;
  }
} */

@media only screen and (max-width: 500px) {
  .lightbox img {
    width: 70%;
    height: 25%;
  }
  .lightbox .close {
    margin-top: 450px;
  }
  .bg-gallery {
    width: 100%;
  }
  .container .card {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 15px auto;
    height: 200px;
    width: 80%;
    overflow: hidden;
    border-radius: 25px;
    /* border:1px solid red */
    /* width: calc(30% - 7px); */
  }
} 

@media only screen and (max-width: 450px) {
  .container .card {
    width: 70%;
  }
} 

@media only screen and (max-width: 320px) {
  .container .card {
    width: 80%;
  }
  .lightbox img {
    height: 20%;
  }
}
