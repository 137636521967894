.footer-main {
  background:var(--primaryColor);
  position: relative;
  width:100%;
  min-height: 250px;
  /* margin-top: 75px; */
  /* border:1px solid #0b3a80; */
  
}
.lastText{
  text-align: justify;
}

.footer-inner {
  display: grid;
  grid-template-columns: 20% 20% 25% 30%;
  grid-column-gap: 1rem;
  width: 85%;
  margin: 0 auto;
  text-align:start;
  justify-content: center;
  /* border:1px solid white; */
  /* font-size: 85%; */
  /* padding-bottom: 20px; */
  padding: 50px 60px 20px 50px;
}

.div1{
  width: 100%;
  padding-right: 100px;
}
.socialmedia_icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 60%;
  margin: 0;
  padding-bottom: 20px;
}

.footer-inner .socialmedia_icons {
  padding-top: 1rem;
}

ul,
li {
  list-style-type: none;
}

.footer-inner p {
  padding-bottom: 1rem;
  color:#d4d3d3;
  /* border:1px solid red; */
}

.footer-inner ul li {
  padding: 0.5rem;
}
.footer-inner a {
  text-decoration: none;
  color: #d4d3d3;
  /* border:1px solid black */
}
.footer-inner a:hover {
  color:var(--mainColor);
  cursor: pointer;
}

.footer-main .wave{
  /* border:none !important; */
  position: absolute;
  top: -200px;
  left:0;
  width: 100%;
  height: 200px;
  background: url("../../../images/footer/wave.svg");
  background-size: 1000px;
  /* border:none */
}
#wave1{
  /* border:none !important; */
  z-index:1000;
  opacity:1;
  bottom:0;
  animation: animateWave 4s linear infinite;
}

#wave2{
  /* border:none !important; */
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave_02 4s linear infinite;
}
#wave3{
  /* border:none !important; */

  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWave_03 3s linear infinite;
}
#wave4{
  /* border:none !important; */
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave_04 3s linear infinite;
}

@keyframes animateWave{
  0%{
    background-position-x: 1000px;
  }
  100%{
    background-position-x: 0px;
  }
}
@keyframes animateWave_02{
  0%{
    background-position-x: 0px;
  }
  100%{
    background-position-x: 1000px;
  }
}
@keyframes animateWave_03{
  0%{
    background-position-x: 1000px;
  }
  100%{
    background-position-x: 0px;
  }
}
@keyframes animateWave_04{
  0%{
    background-position-x: 0px;
  }
  100%{
    background-position-x: 1000px;
  }
}
.copyRights{
  text-align: center;
  color: #d4d3d3;
  display: block;
  padding-top: 10px;
  border-top: 1px solid #1352b0;
  padding-bottom: 20px;
 /* border:1px solid red */
}

@media only screen and (max-width: 1024px) {

  .footer-inner {
    display: grid;
    grid-template-columns: 20% 20% 30% 30%;
    grid-column-gap: 1rem;
    width: 90%;
    margin: 0 auto;
    text-align:start;
    /* padding-bottom: 20px; */
    padding: 50px 60px 20px 50px;
  }
  
  .copyRights {
    flex-direction: column;
  }
  .socialmedia_icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .div1{
    width: 100%;
    margin: 0 auto;
    padding-right:0px;
    padding-bottom: 10px;
  }

}
@media only screen and (max-width: 996px) {
  .footer-inner {
    grid-template-columns: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .copyRights {
    flex-direction: column;
  }
  .socialmedia_icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .div1{
    width: fit-content;
    margin: 0 auto;
    padding-right:0px;
    padding-bottom: 10px;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .lastText{
    text-align: center;
    justify-content: center;
    align-items: center;
  }

}
@media only screen and (max-width: 768px) {
  .footer-inner {
    grid-template-columns: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .copyRights {
    flex-direction: column;
  }
  .socialmedia_icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .div1{
    width: fit-content;
    margin: 0 auto;
    padding-right:0px;
    padding-bottom: 10px;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    text-align: center;
  }

}
@media only screen and (max-width: 425px) {
  .footer-inner {
    grid-template-columns: 100%;
    text-align: center;
    align-items: center;
  }
  .copyRights {
    flex-direction: column;
  }
  .socialmedia_icons {
   
    display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
  }
  .div1{
    width: fit-content;
    margin: 0 auto;
    padding-right:0px;
    padding-bottom: 10px;
  }

}


