nav {
  color: var(--primaryColor);
  position: sticky;
  top: 0;
  background: var(--mainWhite);
  z-index: 1002;
  box-shadow: rgba(45, 80, 130, 0.698) 0px 0px 2px;
}
nav > div {
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 80px;
  margin: 0 auto;
}
.top-nav {
  text-decoration: none;
  list-style-type: none;
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.top-nav a {
  font-family: 'Roboto', sans-serif;
  color: var(--primaryColor);
  text-transform: capitalize;
  padding-bottom: 0.5rem;
  transition: transform 0.8s ease-in-out;
  /* border: 1px solid red; */
}

.top-nav a:hover {
  color: var(--primaryColor);
  border-bottom: 2px solid var(--primaryColor);
}

@media only screen and (max-width: 996px) {
  .top-nav {
    width: 60%;
  }
}

@media only screen and (max-width: 764px) {
  .top-nav {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  
    nav {
      color: var(--primaryColor);
      position: sticky;
      top: 50px;
      background: var(--mainWhite);
      z-index: 1002;
      box-shadow: rgba(45, 80, 130, 0.698) 0px 0px 2px;
    }
  
}
