.Total_Content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.Left_Content {
  position: relative;
  width: 49%;
  text-align: center;
  padding-top: 90px;
}

.leftContentImage1 {
  width: 50%;
  left:0%
}

.leftContentImage2 {
  position: absolute;
  width: 55%;
  height: 430px;
  top: 250px;
  left: 55%;
  border-radius: 25px;
  z-index: 1;
  box-shadow:  3px 0px 10px 0px #204379;
}

.Right_Content {
  position: relative;
  width: 48%;
  padding-left: 250px;
  padding-top: 50px;
  z-index: -1;
}

.RightaboutContent {
  position: absolute;
  border: 1px solid #f2f2f2;
  left: 10px;
  top: 170px;
  padding: 30px;
  padding-left: 60px;
  border-radius: 25px;
  background-color: #f2f2f2;
  box-shadow:  3px 0px 10px 2px #204379;
  width:450px
}

.RightaboutContent h2{
  font-size: 42px;
  padding-bottom: 8px;
  color: var(--primaryColor);
}
.RightaboutContent p{
	/* width: ; */
  font-size: 21px;
  text-align: justify;
}

@media only screen and (max-width: 1024px) {
  .leftContentImage2 {
    width: 85%;
    left: 25%;
    
   
  }
  .RightaboutContent {
    width:85%;
  }
  
  .RightaboutContent h2{
    font-size: 42px;
    padding-bottom: 8px;
    color: var(--primaryColor);
  }
  .RightaboutContent p{
    /* width: ; */
    font-size: 21px;
    text-align: justify;
  }
  

}
@media only screen and (max-width: 768px) {
  .leftContentImage2 {
    width: 85%;
    left: 22%;
    height: 400px;
    top: 200px;   
  }
  .Right_Content {
    position: relative;
    width: 48%;
    padding-left: 130px;
    padding-top: 50px;
    z-index: -1;
  }
  .RightaboutContent {
    width:95%;
    top: 170px;
    padding: 30px;
    /* padding-left: 60px; */
    left: 0;
    right: 10px;
  }
  
  .RightaboutContent h2{
    font-size: 32px;
    padding-bottom: 8px;
    color: var(--primaryColor);
  }
  .RightaboutContent p{
   
    font-size: 18px;
    text-align: justify;
  }
  

}
@media only screen and (max-width: 425px) {
  .leftContentImage2 {
    display: none;
  }
  .leftContentImage1 {
    width: 100%;
    left:0%;
  }
  .Right_Content .RightImage{
    width:200px;
    height: 200px;
    position: absolute;
    top:270px;
    left:-60px
  }
  .Left_Content {
    position: relative;
    width: 30%;
    text-align: center;
    padding-top: 90px;
  }
  .Right_Content {
    position: relative;
    width: 90%;
    padding-left: 0px;
    padding-top: 50px;
    z-index: -1;
  }
  .RightaboutContent {
    width:100%;
    top: 130px;
    padding: 20px;
    /* padding-left: 60px; */
    left: -40px;
    /* right: 10px; */
  }
  
  .RightaboutContent h2{
    font-size: 22px;
    padding-bottom: 8px;
    color: var(--primaryColor);
  }
  .RightaboutContent p{
   
    font-size: 12px;
    text-align: justify;
  }
  

}