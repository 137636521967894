.contact-header {
  background-image: linear-gradient(rgb(12, 12, 12, 0.5), rgba(7, 7, 7, 0.205)),
    url('../../../images/banner/img9.jpg');
  height: 530px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-block {
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  /* background: #df2707; */
  background-image: url('../../../images/banner/background3.jpg');
  background-size: cover;
  height: 600px;
  padding: 1rem;
}
.contact-details {
  position: absolute;
  top: -23%;
  left: 16%;
  height: 450px;
  width: 400px;
  background-color:var(--primaryColor);
  color: var(--mainWhite);
  padding: 1rem;
  text-align: center;
  box-shadow: rgba(45, 80, 130, 0.698) 0px 0px 2px;
  z-index: 1;
  border-radius: 15px;
}
.contact-details h2 {
  color: #00ffd1;
  text-transform: capitalize;
  padding: 3rem 0 2rem 0;
}
.contact-info div {
  padding: 1rem 0;
}
.socialmedia {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  padding:1rem;

}
.socialmedia :hover{
  color: var(--mainColor);
}
.contact-details a {
  color: var(--mainWhite);
}
.contact-form {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: -25%;
  left: 30%;
  height: auto;
  width: 800px;
  padding: 2rem;
  border-radius: 15px;
  background: white;
  box-shadow: rgba(45, 80, 130, 0.698) 0px 0px 2px;
}
.contact-form > div {
  padding: 0 2rem;
  width: 70%;
  /* border: 1px solid red; */
}
.contact-form h2 {
  padding: 1rem 2rem;
  text-align: center;
}
.contact-form p {
  padding-top: 1rem;
}
.contact-form form {
  margin-top: 1rem;
  height: 75%;
  /* border: 1px solid green; */
}
.form-input {
  background: none;
  /* background-color: var(--lightGray); */
  color: var(--paraGraphs);
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  /* border: none; */
  border-radius: 2px;
  border:1px solid #c8dcfa;
  /* border-bottom: 2px solid #3498db; */
  margin: 1rem 0;
}
.form-input:focus {
  outline: none;
}

.contact-form .btns {
  color: var(--mainWhite);
  cursor: pointer;
  text-align: center;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  font-size: 20px;
  border-radius: 10px;
  padding: 5px;
}


@media only screen and (max-width: 1200px) {
  .contact-details {
    left: 5%;
  }
  .contact-form {
    left: 18%;
  }
}

@media only screen and (max-width: 768px) {
  .contact-details {
    position: relative;
    left: 0;
    width: 100%;
    height: 150px;
  }
  .hidden-content {
    display: none;
  }
  .contact-form {
    position: relative;
    left: 0;
    justify-content: center;
     width: 100%;
  }
}

@media only screen and (max-width: 864px) {
  .contact-block {
    padding: 0;
  }
  .contact-form {
    width: 100%;
    padding: 2rem 0;
  }
  .contact-form > div {
    padding: 0 2rem;
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .contact-block {
    position: relative;
    /* display: flex; */
    /* flex-direction: column; */
    /* background: #df2707; */
    background-image: url('../../../images/banner/background3.jpg');
    background-size: cover;
    height: 110vh;
    padding: 0;
  }
  .contact-form {
    width: 100%;
    padding: 2rem 0;
    height: fit-content;
  }
  .contact-form > div {
    padding: 0 2rem;
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .contact-block {
    position: relative;
    /* display: flex; */
    /* flex-direction: column; */
    /* background: #df2707; */
    background-image: url('../../../images/banner/background3.jpg');
    background-size: cover;
    height: 120vh;
    padding: 0;
  }
  .contact-form {
    width: 100%;
    padding: 2rem 0;
    height: fit-content;
  }
  .contact-form > div {
    padding: 0 2rem;
    width: 100%;
  }
}

