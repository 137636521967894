header {
  color: var(--mainWhite);
  background:var(--primaryColor);
  height: 50px;
  padding-top: 5px;
}
header > div {
  display: flex;
  width: 80%;
  justify-content: space-between;
  /* border: 1px solid green; */
  margin: 0 auto;
  padding: 0.5rem 0;
}
.top_nav-details {
  display: flex;
  justify-content: space-between;
  width: 40%;
  /* border: 1px solid yellow; */
}
.top_nav-socials {
  /* border: 1px solid white; */
  width: 20%;
}

header a {
  color: white;
}
.top_nav-details a {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1400px) {
  .top_nav-details {
    width: 40%;
    /* border: 1px solid white; */
  }
}
@media only screen and (max-width: 1200px) {
  .top_nav-details {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .top_nav-socials {
    display: none;
  }
  .top_nav-details {
    width: 100%;
    padding:0 10px ;
  }
}

@media only screen and (max-width: 500px) {
  header > div {
    width: 100%;
  }
  header{
    position: sticky;
    top: 0;
    z-index: 1002;
  }

}
