a {
  text-decoration: none;
}

.bannerImg {
  position: relative;
  width:100%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100%;
  color: white;
  border:"1px solid red"
  /* opacity: 0.5; */
}

.bannerImg > div {
  position: absolute;
  width: 35vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  /* transform: translate(-100%, 0%); */
  color: #fff;
  text-align: center;
  background-color:#000;
  opacity:0.75; 
  /* align-items:"center" */
}
.bannerImg > div h2 {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}
.bannerImg > div p {
  background: none;
  letter-spacing: 2px;
  padding-bottom: 1rem;
}

.aws-btn {
  --slider-height-percentage: 46%;
  --slider-transition-duration: 525ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 20px;
  --organic-arrow-color: var(--mainColor);
  --control-button-width: 3%;
  --control-button-height: 8%;
  --control-button-border-radius:10px;
  --control-button-background: #154992b2;
  --control-bullet-color: #2d5082b2;
  --control-bullet-active-color: var(--mainColor);
  --loader-bar-color: #3498db11;
  --loader-bar-height: 10px;
}

.awssld {
  --content-background-color: #204379;
  /* #8d89c4 */
  /* #4baae2 */
}

.bannerImg .button {
  color: var(--mainWhite);
  cursor: pointer;
  text-align: center;
  border: 1px solid var(--mainWhite);
  background-color: var(--mainColor);
  font-size: 20px;
  border-radius: 10px;
  padding: 5px;
 
}

.bannerImg .btn:hover{
  background-color: var(--mainColor);
}


.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small{
  width: 270px;
  height: 290px;
  left: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 1024px) {
  .bannerImg > div {
    width: 450px;
    /* transform: translate(-65%, -0%); */
  }
  .aws-btn {
    --slider-height-percentage: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .bannerImg > div {
    width: 350px;
    /* transform: translate(-78%, -0%); */
  }
  .aws-btn {
    --slider-height-percentage: 80%;
  }
}
@media only screen and (max-width: 425px) {

  .bannerImg{
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .bannerImg > div {
  position: absolute;
  width: 250px;
  height: fit-content;
  color: #fff;
  text-align: center;
  opacity:0.75; 
  margin: 0 auto;
  left:auto;
  background-color: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  

  }
  .bannerImg > div h2{
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  /* padding-top: 10px; */
  width: 200px;
  /* background-color: #000; */
  opacity:0.75;
 
  font-size:24px ;

  }
  .bannerImg > div p {
    width:200px;
    height: fit-content;
    /* background-color: #000; */
    opacity:0.75;
   
    margin-bottom: 1rem;
    font-size: 14px;
    text-align: center;
  }
   
  .aws-btn {
    --slider-height-percentage: 100%;
    --loader-bar-color: #3498db11;
    --loader-bar-height: 10px;
  }
}
