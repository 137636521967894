@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


.Total_Project {
  position: relative;
  justify-content: center;
  align-items: center;
  background: var(--mainWhite);
  padding-top: 40px;
}
.Total_Project h2 {
  text-align: center;
  font-size: 32px;
  padding: 20px 0px;
}
.Total_Project p {
  text-align: center;
  padding: 1rem;
}

.Project_container {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  align-items: center;
  /* min-height: 600px; */
  margin: 10px auto;
  padding: 0 !important;
}

.containers {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 100%;
  padding: 5px;
  
} 

.containers .servicescard {
  display: flex;
  /* width:400px; */
  flex-direction: column;
  position: relative;
  margin: 25px;
  min-height: 200px;
  overflow: hidden;
  box-shadow: 1px 1px 6px 1px grey;
  border-radius: 20px;
  color: white;

}

.servicescard:nth-child(odd):hover {
  background: linear-gradient(319deg, rgba(32,67,121,1) 8%, rgba(185,0,74,1) 100%);

}
.servicescard:nth-child(even):hover{
  background: linear-gradient(319deg, rgba(2,0,36,1) 0%, rgba(9,57,121,1) 17%, rgba(0,212,255,1) 100%);

}
.containers .servicescard:hover {
  cursor: pointer;
  /* box-shadow:  0px 0px 10px 4px #204379; */
  border-radius: 25px;
  color: white;
}

.servicescard:nth-child(even){
  background: white;
  color: #204379;
  /* box-shadow:  0px 0px 10px 4px #204379 */
  /* background: none; */
}

/* .servicescard:nth-child(5){
  background: linear-gradient(319deg, rgba(32,67,121,1) 8%, rgba(185,0,74,1) 100%) !important;
  color: white !important;

} */

.servicescard:nth-child(odd){
  background: white;
  color: var(--primaryColor);
  /* box-shadow:  0px 0px 10px 4px #204379 */
  /* background: none; */
}

.cardMain {
  padding: 20px;
  text-align: center;

}

.cardContentImg img {
  position: absolute;
  width: 140px;
  left: 49px;
  top: -45px;
}

.cardContentImg h2 {
  position: absolute;
  top: -30px;
  left: 110px;
  color: white;
  font-family: 'EB Garamond', serif;
}

.card--title {
  font-size: 25px;
  padding-top: 0px;
}

.cardInfo {
  width: 70%;
  margin: auto;
  padding-top: 0px;
  font-size: 14px;
}

.containers .servicescard .servicescard--info {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease;
}

.containers .servicescard .servicescard--info h1 {
  color: #fff;
  font-size: 2em;
  text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000;
}

.containers .servicescard .servicescard--info label {
  color: var(--mainColor);
  font-size: medium;
  margin-top: 20px;
  text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000;
}

.containers .servicescard .servicescard--info button {
  margin-top: 10px;
  border: none;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  background-color: #b9004a;
  padding: 7px 12px;
}

.servicescard--info button {
  cursor: pointer;
}

.containers .servicescard .servicescard--info button:hover {
  background-color: #204379;
}

.containers .servicescard .servicescard--info h1,
.containers .servicescard .servicescard--info label,
.containers .servicescard .servicescard--info button {
  transform: translateX(-400px);
  transition: 0.5s ease;
}

.containers .servicescard:hover .servicescard--info {
  opacity: 1;
}

.containers .servicescard:hover .servicescard--info h1,
.containers .servicescard:hover .servicescard--info label,
.containers .servicescard:hover .servicescard--info button {
  transform: translateX(0px);
  transition: 0.5s ease;
}

.workbtns:nth-child(odd) {
  border-radius: 5px;
  padding: 5px 15px;
  background-color:white;
  color:var(--mainColor);
  border: 1px solid var(--mainColor);
}


@media only screen and (max-width: 864px) {
  .containers {
    grid-template-columns: auto auto;
    /* border: 1px solid green; */
  }
  
}

@media only screen and (max-width: 600px) {
  .containers {
    grid-template-columns: auto;
  }
  
}


@media only screen and (max-width: 500px) {
  .containers .servicescard {
    width: 50%;
  }
  
  .bg-gallery {
    width: 100%;
  }
} 

@media only screen and (max-width: 425px) {
  .containers .servicescard {
    width: 60%;
    /* grid-template-columns: auto auto auto; */
    margin: 25px auto;
  }
} 

@media only screen and (max-width: 320px) {
  .containers .servicescard {
    width: 90%;
    margin: 25px auto;

  }
  
}

@media screen and (max-width: 1300px) {
  .Project_container {
    grid-template-columns: auto auto auto;
    grid-row-gap: 150px;
    padding: 6rem 0;
    
  }
}
@media screen and (max-width: 996px) {
  .Total_Project {
    justify-content: center;
    align-items: center;
    background: #faf7f8;
    padding-top: 20px;
  }
  .Project_container {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 764px) {
  .Project_container {
    grid-template-columns: auto;
  }
}
