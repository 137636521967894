.bg-gallery {
  height: 600px;
  /* padding-top: 150px; */
  background-color: #204379;
  box-shadow: 0px 0px 1px 0px #508fb9;
  border: 1px solid white;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.artGallery {
  width:50%;
  height: 300px;
  margin-left: 45%;
  /* margin: 0 auto; */
  /* border: 1px solid red; */
  text-align: end;
  /* justify-content: end;
  align-items: end; */
  /* text-align: end; */
}
.bg-gallery h1 {
  text-align:center;
  /* padding-right: 350px; */
  font-size: 60px;
  font-family: 'Barlow', sans-serif;
  color: white;
  text-shadow: 2px 2px #b9004a;
}

.bg-gallery h5 {
  font-size: 30px;
  text-align: center;
  /* padding-right: 280px; */
  color: white;
  text-shadow: 1px 0px #b9004a;
  /* margin-left: 50px; */
}

.bg-gallery p {
 
  font-size: 20px;
  text-align: center;
  /* padding-left: 195px; */
  color: white;
  /* text-shadow: 1px 0px #b9004a; */
  /* font-weight: bold; */
  /* margin-left: 50px; */
}

.gallery-heading-image {
  text-align: center;
  padding: 20px 0px;
}

.gallery-heading-image h1 {
  text-transform: uppercase;
  /* padding: 30px 0px; */
  padding-top: 40px;
}

.gallery-heading-video h1 {
  text-align: center;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 35px;
}

.gallery-video-main {
  display: flex;
  justify-content: space-around;
  width: 71%;
  /* border:1px solid red; */
  margin: auto;
  padding-bottom: 50px;
}

.video-content-one {
  width: 45%;
  /* box-shadow: 1px 1px red; */
}

.video-content-two {
  width: 45%;
}

/* .awssld { */
  /* --content-background-color: #204379; */
  /* #8d89c4 */
  /* #4baae2 */
/* } */

.bgcolor {
  background-color: #204379;
  position: relative;
}

.circle{
  position: absolute;
  border-radius: 50%;
  /* background:red; */
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 0px 0px #508fb9;
}

/* .small{ */
  /* width: 200px; */
  /* height: 210px; */
  /* left: -100px; */
  /* bottom: -100px; */
/* } */

/* .medium{ */
  /* width: 300px; */
  /* height: 300px; */
  /* left: -200px; */
  /* bottom: -200px; */
/* } */

/* .large{ */
  /* width: 400px; */
  /* height: 400px; */
  /* left: -300px; */
  /* bottom: -300px; */
/* } */

/* .xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
} */

/* .xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
} */


@media only screen and (max-width: 1024px) {
  .gallery-video-main {
    width: 93%;
  }
  .bg-gallery {
    height: 600px;
    /* padding-top: 150px; */
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid white;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
}

@media only screen and (max-width: 768px) {
  .gallery-video-main {
    width: 95%;
  }

  .video-common {
    width: 100%;
    height: 184px;
  }
  .circle{
    display: none;
  }
  .bg-gallery {
    height: 65vh;
    padding-top: 60px;
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid white;
    align-items: center;
    justify-content: center;
    border:"1px solid red"
  }
  .artGallery {
    width:90%;
    height: 300px;
    margin-left: 0%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 60px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 30px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 20px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}

@media only screen and (max-width: 500px) {
  .gallery-video-main {
    width: 98%;
  }

  .video-common {
    width: 100%;
    height: 124px;
  }
  .bg-gallery {
    height: 65vh;
    padding-top: 120px;
    padding-right: 50px;
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid #204379;
    /* align-items: center; */
  }
  .artGallery {
    width:95%;
    height: 300px;
    margin-left: 15%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 45px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 28px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 15px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}

@media only screen and (max-width: 440px) {
  .bg-gallery {
    text-align: center;
    padding-top: 100px;
  }
  .gallery-video-main {
    width: 50%;
    margin: auto;
    flex-direction: column;
  }
  .video-content-one,
  .video-content-two {
    width: 100%;
    padding-bottom: 10px;
  }
  .artGallery {
    width:95%;
    height: 300px;
    margin-left: 15%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 45px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 28px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 15px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}

@media only screen and (max-width: 375px) {
  .gallery-video-main {
    width: 65%;
  }
  .bg-gallery {
    height: 60vh;
    padding-top: 100px;
    padding-right: 50px;
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid #204379;
    /* align-items: center; */
  }
  .artGallery {
    width:95%;
    height: 300px;
    margin-left: 15%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 40px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 25px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 14px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}

@media only screen and (max-width: 320px) {
  .gallery-video-main {
    width: 85%;
  }
  .bg-gallery {
    height: 60vh;
    padding-top: 100px;
    padding-right: 30px;
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid #204379;
    /* align-items: center; */
  }
  .artGallery {
    width:95%;
    height: 300px;
    margin-left: 10%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 38px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 25px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 15px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}
