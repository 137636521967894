/* font family */
@import url('https://fonts.googleapis.com/css2?family=Barlow&family=EB+Garamond&family=Quicksand&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'EB Garamond', serif, 'Quicksand', sans-serif;
  /* font-family: 'Poppins', sans-serif; */
}
html {
  scroll-behavior: smooth;
}

:root {
  --mainColor: #02b595;
  --mainWhite: #fff;
  --mainBlack: #000;
  --paraGraphs: #2d2d2d;
  --headings: #202020;
  --grayBack: #757575;
  --lightGray: #e6e1e1;
  --mainGold: gold;
  --primaryColor:#00396E;
}

/* loader */
.loader_bg {
  position: fixed;
  z-index: 9999999;
  background: #fff;
  width: 100%;
  height: 100%;
}
.loader {
  border: 0 solid transparent;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: absolute;
  top: calc(50vh - 75px);
  left: calc(49vw - 75px);
}
.loader:before,
.loader:after {
  content: '';
  /* border: 1em solid #ea174a; */
  background: url('./components/images/QJM.png');
  background-size: 100%;
  background-repeat: no-repeat;
  /* border-radius: 50%; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* animation: loader 2s linear infinite; */
  /* opacity: 0; */
}
/* .loader:before {
  animation-delay: 0.5s;
} */
/* @keyframes loader {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
} */
/* loaders */

.btn {
  padding: 0.2rem;
  border-radius: 2px;
  color: var(--mainWhite);
  background: var(--mainColor);
}
