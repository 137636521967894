.About_Director {
  display: flex;
  padding-top: 300px;
  padding-bottom: 95px;
}

.AboutleftContent {
	position: relative;
  	width: 50%;
	/* margin-left: 100px; */
}

.AbtLeftImg1 {
	width: 70%;
}

.AbtLeftImg2 {
	position: absolute;
	width: 75%;
	left: 110px;
	top: 50px;
}


.AboutRightContent {
  width: 50%;
  padding-top: 50px;
}

.AboutRightContent h2 {
	font-size: 40px;
	font-family: 'Barlow', sans-serif;
	color:var(--primaryColor);

}

.AboutRightContent p {
	padding-top: 15px;
	width: 85%;
	font-size: 22px;
	padding-bottom: 20px;
	text-align: justify;
}

.phnIcon {
	font-size: 30px;
	color: var(--primaryColor);
	
}

.dialNumber {
	padding-left: 13px;
	padding-top: 19px;
	font-size: 28px;
	color: #000;
}

.dialNumber:hover {
	color: var(--primaryColor);
}
@media only screen and (max-width: 1024px) {
	.AboutleftContent {
		position: relative;
		  width: 50%;
		  padding-top: 150px;
		/* margin-left: 100px; */
	}
	.AbtLeftImg2 {
		top: 150px;
	}
	.AboutRightContent {
		width: 50%;
		padding-top: 100px;
	  }
	.AboutRightContent h2 {
		font-size: 40px;
		font-family: 'Barlow', sans-serif;
		color:var(--primaryColor);
	
	}
	
	.AboutRightContent p {
		padding-top: 15px;
		width: 85%;
		font-size: 21px;
		padding-bottom: 20px;
	}
}
@media only screen and (max-width: 768px) {
	.AboutleftContent {
		position: relative;
		  width: 45%;
		  padding-top: 200px;
		/* transform: translate(-50%,-50%); */
		/* margin-left: 100px; */
	}
	.AbtLeftImg2 {
		top: 150px;
		height: 300px;
	}
	.AboutRightContent {
		width: 55%;
		padding-top: 100px;
	  }
	.AboutRightContent h2 {
		font-size: 32px;
		font-family: 'Barlow', sans-serif;
		color:var(--primaryColor);
	
	}
	
	.AboutRightContent p {
		padding-top: 15px;
		width: 85%;
		font-size: 18px;
		padding-bottom: 20px;
	}
}
@media only screen and (max-width: 425px) {
	.AboutleftContent {
		position: relative;
		  width: 45%;
		  padding-top: 200px;
		display: none;
	}
	.AbtLeftImg2 {
		top: 150px;
		height: 300px;
		display: none;
	}
	.AboutRightContent {
		width: 95%;
		padding-top: 20px;
		padding-left: 40px;
	  }
	.AboutRightContent h2 {
		font-size: 22px;
		font-family: 'Barlow', sans-serif;
		color:var(--primaryColor);
	
	}
	.AboutRightContent p {
		padding-top: 15px;
		width: 85%;
		font-size: 12px;
		padding-bottom: 20px;
	}
	.phnIcon {
		font-size: 20px;
		color: var(--primaryColor);
		
	}
	
	.dialNumber {
		padding-left: 13px;
		padding-top: 19px;
		font-size: 18px;
		color: #000;
	}
}