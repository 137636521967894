/* recent work */
.recent-work {
  width: 70%;
  justify-content: center;
  align-items: center;
  /* border: 1px solid blue; */
  margin: 0 auto;
  padding-bottom: 180px;
  /* padding: 4rem 0; */
  /* background: #dcdde1; */
}

.recent-work h2 {
  font-size: 37px;
  padding-bottom: 40px;
}

.recent-work_items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 2rem;
  margin: 2rem 0;
  /* border: 1px solid green; */
}
.recent-work_singleitem {
  position: relative;
  padding: 1rem 1rem;
  /* border:1px solid red; */
 box-shadow:  0px 0px 10px 5px #3d6cb4;
 border-radius: 20px;
}

.workContent {
  padding-bottom: 20px;
 
}

.workContent img {
  position: absolute;
  width: 140px;
  left: 49px;
  top: -45px;
}

.workContent h2 {
  position: absolute;
  top: -30px;
  left: 110px;
  color: white;
  font-family: 'EB Garamond', serif;
}

.recent-work-heading {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  color: var(--mainColor);
  /* border: 1px solid red; */
}
.recent-work-heading ~ p {
  padding: 0;
  text-align: justify;
  line-height: 1.5;
}

.workbtn {
  border-radius: 15px;
  padding: 5px 15px;
  background-color:var(--mainColor);
  color:white
}

@media only screen and (max-width: 1024px) {
 
  .recent-work_singleitem p {
    font-size: 12px;
  }
  .recent-work {
    width: 80%;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
    margin: 0 auto;
    padding-bottom: 180px;
    /* padding: 4rem 0; */
    /* background: #dcdde1; */
  }
}
@media only screen and (max-width: 996px) {
  .recent-work_items {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 768px) {
  .recent-work_items {
    grid-template-columns: auto auto;
  }
}
@media only screen and (max-width: 425px) {
  .recent-work_items {
    grid-template-columns: auto;
  }
  .recent-work_singleitem p {
    font-size: 16px;
  }
}
