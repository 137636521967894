@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow&family=EB+Garamond&family=Quicksand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* * {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #ffffff;
} */

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #8f54a0;
  border-radius: 10px;
  border: 3px solid #ffffff;
} */


a {
  text-decoration: none;
}

.bannerImg {
  position: relative;
  width:100%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100%;
  color: white;
  border:"1px solid red"
  /* opacity: 0.5; */
}

.bannerImg > div {
  position: absolute;
  width: 35vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  /* transform: translate(-100%, 0%); */
  color: #fff;
  text-align: center;
  background-color:#000;
  opacity:0.75; 
  /* align-items:"center" */
}
.bannerImg > div h2 {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}
.bannerImg > div p {
  background: none;
  letter-spacing: 2px;
  padding-bottom: 1rem;
}

.aws-btn {
  --slider-height-percentage: 46%;
  --slider-transition-duration: 525ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 20px;
  --organic-arrow-color: var(--mainColor);
  --control-button-width: 3%;
  --control-button-height: 8%;
  --control-button-border-radius:10px;
  --control-button-background: #154992b2;
  --control-bullet-color: #2d5082b2;
  --control-bullet-active-color: var(--mainColor);
  --loader-bar-color: #3498db11;
  --loader-bar-height: 10px;
}

.awssld {
  --content-background-color: #204379;
  /* #8d89c4 */
  /* #4baae2 */
}

.bannerImg .button {
  color: var(--mainWhite);
  cursor: pointer;
  text-align: center;
  border: 1px solid var(--mainWhite);
  background-color: var(--mainColor);
  font-size: 20px;
  border-radius: 10px;
  padding: 5px;
 
}

.bannerImg .btn:hover{
  background-color: var(--mainColor);
}


.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small{
  width: 270px;
  height: 290px;
  left: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 1024px) {
  .bannerImg > div {
    width: 450px;
    /* transform: translate(-65%, -0%); */
  }
  .aws-btn {
    --slider-height-percentage: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .bannerImg > div {
    width: 350px;
    /* transform: translate(-78%, -0%); */
  }
  .aws-btn {
    --slider-height-percentage: 80%;
  }
}
@media only screen and (max-width: 425px) {

  .bannerImg{
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .bannerImg > div {
  position: absolute;
  width: 250px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #fff;
  text-align: center;
  opacity:0.75; 
  margin: 0 auto;
  left:auto;
  background-color: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  

  }
  .bannerImg > div h2{
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  /* padding-top: 10px; */
  width: 200px;
  /* background-color: #000; */
  opacity:0.75;
 
  font-size:24px ;

  }
  .bannerImg > div p {
    width:200px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* background-color: #000; */
    opacity:0.75;
   
    margin-bottom: 1rem;
    font-size: 14px;
    text-align: center;
  }
   
  .aws-btn {
    --slider-height-percentage: 100%;
    --loader-bar-color: #3498db11;
    --loader-bar-height: 10px;
  }
}

.choose-us {
  display: flex;
  width: 70%;
  /* justify-content: space-between; */
  /* border: 1px solid green; */
  margin: 0 auto;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
}

.choose-us_info {
  width: 60%;
  /* border: 1px solid red; */
}
.choose-us_info p {
  color: var(--grayBack);
  padding: 0;
}

.chooseHeading {
	font-size: 33px;
	padding-bottom: 20px;
}

.choose-us_img {
  display: flex;
  justify-content: center;
  width: 42%;
  padding-left: 35px;
  /* border: 1px solid green; */
  /* padding: 1rem; */
}

/* home services */
.home-services {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  padding-top: 1rem;
}

.home-services-items {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
  /* border: 1px solid red; */
}

.home-services-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  padding: 1.8rem;
  border-radius: 50%;
  background-color: var(--primaryColor);
  
}

.home-services-logo:hover {
	background-color: var(--mainColor);
}

.home-services-logo:hover a {
	color: white;
}

.home-services-logo a {
	color: white;
}


/* #204379 */

.home-services-logo ~ div {
  width: 75%;
}

.home-services-items h3 {
	padding-left: 20px;
	color: var(--primaryColor);
}

.home-services-items p {
  color: var(--grayBack);
  text-align: justify;
  line-height: 1.5;
  padding-left: 20px;
}

@media only screen and (max-width: 996px) {
  .choose-us {
    flex-direction: column;
  }
  .choose-us_info,
  .choose-us_img {
    width: 100%;
  }
  .home-services-items p  {
	padding-left: 0;
  }
  .home-services-items h3 {
	padding-left: 0px;
}
}

@media only screen and (max-width: 768px) {
  .home-services {
    grid-template-columns: auto;
  }
  .home-services-items p  {
	padding-left: 0;
  }
  .home-services-items h3 {
	padding-left: 0px;
}
.home-services-logo{
  height: 60px;
  width: 60px;
}
}
@media only screen and (max-width: 425px) {
  .home-services {
    grid-template-columns: auto;
  }
  .home-services-items p  {
	padding-left: 0;
  }
  .home-services-items h3 {
	padding-left: 0px;
}
.home-services-logo{
  height: 20px;
  width: 20px
}
}


/* recent work */
.recent-work {
  width: 70%;
  justify-content: center;
  align-items: center;
  /* border: 1px solid blue; */
  margin: 0 auto;
  padding-bottom: 180px;
  /* padding: 4rem 0; */
  /* background: #dcdde1; */
}

.recent-work h2 {
  font-size: 37px;
  padding-bottom: 40px;
}

.recent-work_items {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 2rem;
  margin: 2rem 0;
  /* border: 1px solid green; */
}
.recent-work_singleitem {
  position: relative;
  padding: 1rem 1rem;
  /* border:1px solid red; */
 box-shadow:  0px 0px 10px 5px #3d6cb4;
 border-radius: 20px;
}

.workContent {
  padding-bottom: 20px;
 
}

.workContent img {
  position: absolute;
  width: 140px;
  left: 49px;
  top: -45px;
}

.workContent h2 {
  position: absolute;
  top: -30px;
  left: 110px;
  color: white;
  font-family: 'EB Garamond', serif;
}

.recent-work-heading {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  color: var(--mainColor);
  /* border: 1px solid red; */
}
.recent-work-heading ~ p {
  padding: 0;
  text-align: justify;
  line-height: 1.5;
}

.workbtn {
  border-radius: 15px;
  padding: 5px 15px;
  background-color:var(--mainColor);
  color:white
}

@media only screen and (max-width: 1024px) {
 
  .recent-work_singleitem p {
    font-size: 12px;
  }
  .recent-work {
    width: 80%;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
    margin: 0 auto;
    padding-bottom: 180px;
    /* padding: 4rem 0; */
    /* background: #dcdde1; */
  }
}
@media only screen and (max-width: 996px) {
  .recent-work_items {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 768px) {
  .recent-work_items {
    grid-template-columns: auto auto;
  }
}
@media only screen and (max-width: 425px) {
  .recent-work_items {
    grid-template-columns: auto;
  }
  .recent-work_singleitem p {
    font-size: 16px;
  }
}

.Total_Content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.Left_Content {
  position: relative;
  width: 49%;
  text-align: center;
  padding-top: 90px;
}

.leftContentImage1 {
  width: 50%;
  left:0%
}

.leftContentImage2 {
  position: absolute;
  width: 55%;
  height: 430px;
  top: 250px;
  left: 55%;
  border-radius: 25px;
  z-index: 1;
  box-shadow:  3px 0px 10px 0px #204379;
}

.Right_Content {
  position: relative;
  width: 48%;
  padding-left: 250px;
  padding-top: 50px;
  z-index: -1;
}

.RightaboutContent {
  position: absolute;
  border: 1px solid #f2f2f2;
  left: 10px;
  top: 170px;
  padding: 30px;
  padding-left: 60px;
  border-radius: 25px;
  background-color: #f2f2f2;
  box-shadow:  3px 0px 10px 2px #204379;
  width:450px
}

.RightaboutContent h2{
  font-size: 42px;
  padding-bottom: 8px;
  color: var(--primaryColor);
}
.RightaboutContent p{
	/* width: ; */
  font-size: 21px;
  text-align: justify;
}

@media only screen and (max-width: 1024px) {
  .leftContentImage2 {
    width: 85%;
    left: 25%;
    
   
  }
  .RightaboutContent {
    width:85%;
  }
  
  .RightaboutContent h2{
    font-size: 42px;
    padding-bottom: 8px;
    color: var(--primaryColor);
  }
  .RightaboutContent p{
    /* width: ; */
    font-size: 21px;
    text-align: justify;
  }
  

}
@media only screen and (max-width: 768px) {
  .leftContentImage2 {
    width: 85%;
    left: 22%;
    height: 400px;
    top: 200px;   
  }
  .Right_Content {
    position: relative;
    width: 48%;
    padding-left: 130px;
    padding-top: 50px;
    z-index: -1;
  }
  .RightaboutContent {
    width:95%;
    top: 170px;
    padding: 30px;
    /* padding-left: 60px; */
    left: 0;
    right: 10px;
  }
  
  .RightaboutContent h2{
    font-size: 32px;
    padding-bottom: 8px;
    color: var(--primaryColor);
  }
  .RightaboutContent p{
   
    font-size: 18px;
    text-align: justify;
  }
  

}
@media only screen and (max-width: 425px) {
  .leftContentImage2 {
    display: none;
  }
  .leftContentImage1 {
    width: 100%;
    left:0%;
  }
  .Right_Content .RightImage{
    width:200px;
    height: 200px;
    position: absolute;
    top:270px;
    left:-60px
  }
  .Left_Content {
    position: relative;
    width: 30%;
    text-align: center;
    padding-top: 90px;
  }
  .Right_Content {
    position: relative;
    width: 90%;
    padding-left: 0px;
    padding-top: 50px;
    z-index: -1;
  }
  .RightaboutContent {
    width:100%;
    top: 130px;
    padding: 20px;
    /* padding-left: 60px; */
    left: -40px;
    /* right: 10px; */
  }
  
  .RightaboutContent h2{
    font-size: 22px;
    padding-bottom: 8px;
    color: var(--primaryColor);
  }
  .RightaboutContent p{
   
    font-size: 12px;
    text-align: justify;
  }
  

}
.About_Director {
  display: flex;
  padding-top: 300px;
  padding-bottom: 95px;
}

.AboutleftContent {
	position: relative;
  	width: 50%;
	/* margin-left: 100px; */
}

.AbtLeftImg1 {
	width: 70%;
}

.AbtLeftImg2 {
	position: absolute;
	width: 75%;
	left: 110px;
	top: 50px;
}


.AboutRightContent {
  width: 50%;
  padding-top: 50px;
}

.AboutRightContent h2 {
	font-size: 40px;
	font-family: 'Barlow', sans-serif;
	color:var(--primaryColor);

}

.AboutRightContent p {
	padding-top: 15px;
	width: 85%;
	font-size: 22px;
	padding-bottom: 20px;
	text-align: justify;
}

.phnIcon {
	font-size: 30px;
	color: var(--primaryColor);
	
}

.dialNumber {
	padding-left: 13px;
	padding-top: 19px;
	font-size: 28px;
	color: #000;
}

.dialNumber:hover {
	color: var(--primaryColor);
}
@media only screen and (max-width: 1024px) {
	.AboutleftContent {
		position: relative;
		  width: 50%;
		  padding-top: 150px;
		/* margin-left: 100px; */
	}
	.AbtLeftImg2 {
		top: 150px;
	}
	.AboutRightContent {
		width: 50%;
		padding-top: 100px;
	  }
	.AboutRightContent h2 {
		font-size: 40px;
		font-family: 'Barlow', sans-serif;
		color:var(--primaryColor);
	
	}
	
	.AboutRightContent p {
		padding-top: 15px;
		width: 85%;
		font-size: 21px;
		padding-bottom: 20px;
	}
}
@media only screen and (max-width: 768px) {
	.AboutleftContent {
		position: relative;
		  width: 45%;
		  padding-top: 200px;
		/* transform: translate(-50%,-50%); */
		/* margin-left: 100px; */
	}
	.AbtLeftImg2 {
		top: 150px;
		height: 300px;
	}
	.AboutRightContent {
		width: 55%;
		padding-top: 100px;
	  }
	.AboutRightContent h2 {
		font-size: 32px;
		font-family: 'Barlow', sans-serif;
		color:var(--primaryColor);
	
	}
	
	.AboutRightContent p {
		padding-top: 15px;
		width: 85%;
		font-size: 18px;
		padding-bottom: 20px;
	}
}
@media only screen and (max-width: 425px) {
	.AboutleftContent {
		position: relative;
		  width: 45%;
		  padding-top: 200px;
		display: none;
	}
	.AbtLeftImg2 {
		top: 150px;
		height: 300px;
		display: none;
	}
	.AboutRightContent {
		width: 95%;
		padding-top: 20px;
		padding-left: 40px;
	  }
	.AboutRightContent h2 {
		font-size: 22px;
		font-family: 'Barlow', sans-serif;
		color:var(--primaryColor);
	
	}
	.AboutRightContent p {
		padding-top: 15px;
		width: 85%;
		font-size: 12px;
		padding-bottom: 20px;
	}
	.phnIcon {
		font-size: 20px;
		color: var(--primaryColor);
		
	}
	
	.dialNumber {
		padding-left: 13px;
		padding-top: 19px;
		font-size: 18px;
		color: #000;
	}
}
.Backimg_Total {
 
  display: flex;
  width: 100%;
  background-color: var(--primaryColor);
  justify-content: space-between;
  /* align-items: center; */
  padding-top: 60px;
  height: 680px;
  overflow: hidden;
  /* padding-left: 100px; */
}

.aboutHeading {
  width: 40%;
  /* padding-top: 100px; */
  padding-left: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  
}

.aboutHeading h2 {
  font-size: 50px;
}

.aboutHeading p {
  font-size: 35px;
 
}
.aboutimgMain {
  position: relative;
  animation: MyDiv;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

@keyframes MyDiv {
  0%   {right: -650px; top: 0px;}
  100%  {right: 0px; top: 0px;}
}

.aboutimage {
  width: 680px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  /* top: 70px; */
}

@media only screen and (max-width: 1024px) {
  .aboutimage {
    width: 580px;
    border-radius: 50%;
    position: absolute;
    right: -70px;
    /* top: 70px; */
  }
  .Backimg_Total {
    height: 550px;
    overflow-x: hidden;
    width:100%;
    padding-left: 0px;
    padding-top: 0px;
  }
  .aboutHeading h2 {
    font-size: 40px;
  }
  
  .aboutHeading p {
    font-size: 28px;
   
  }

}
@media only screen and (max-width: 768px) {
  
  .aboutimage {
    width: 450px;
    border-radius: 50%;
    position: absolute;
    right: -70px;
    /* top: 70px; */
  }
  .aboutHeading h2 {
    font-size: 30px;
  }
  
  .aboutHeading p {
    font-size: 18px;
   
  }
  .Backimg_Total {
    height: 450px;
    width: 100%;
    overflow: hidden;

   
  }

}
@media only screen and (max-width: 425px) {
  .aboutimage {
    width: 250px;
    border-radius: 50%;
    position: absolute;
    right: -70px;
    top: 70px;
  }
  .aboutHeading h2 {
    font-size: 20px;
  }
  
  .aboutHeading p {
    font-size: 12px;
   
  }
  .Backimg_Total {
    height: 350px;
    overflow: hidden;
    padding-top: 0px;
    width: 100%;
  }
  .aboutHeading {
    width: 50%;
    /* padding-top: 100px; */
    padding-left: 0px;
    text-align: center;
    color: white;
    
  }

}


*{
    font-family: 'Roboto',  sans-serif ;
}
.gallery-lightbox {
  position: relative;
  z-index: 1001;
}

.container {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  padding: 5px;
}

.container .card {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 25px;
  height: 300px;
  overflow: hidden;
  width:90%;
  border-radius: 25px;

}
.container .card:hover {
  cursor: pointer;
  box-shadow:  0px 0px 10px 4px #204379;
}
.container .card img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.container .card .card--info {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease;
}

.container .card .card--info h1 {
  color: #fff;
  font-size: 2em;
  text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000;
}

.container .card .card--info label {
  color: var(--mainColor);
  font-size: medium;
  margin-top: 20px;
  text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000;
}

.container .card .card--info button {
  margin-top: 10px;
  border: none;
  /* outline: none; */
  /* background: var(--mainColor); */
  color: #fff;
  font-size: 18px;
  /* text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000; */
  /* width: 80px; */
  /* height: 25px; */
  border-radius: 5px;
  background-color: var(--primaryColor);
  padding: 7px 12px;
}

.card--info button {
  cursor: pointer;
}

.container .card .card--info button:hover {
  background-color: var(--mainColor);
}

.container .card .card--info h1,
.container .card .card--info label,
.container .card .card--info button {
  transform: translateX(-400px);
  transition: 0.5s ease;
}

.container .card:hover .card--info {
  opacity: 1;
}

.container .card:hover .card--info h1,
.container .card:hover .card--info label,
.container .card:hover .card--info button {
  transform: translateX(0px);
  transition: 0.5s ease;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  min-height: 100%;
  width: 100%;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);
  display: none;
}

.lightbox .carousel {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  z-index: 6;
}

.lightbox .carousel:hover {
  background: rgba(255, 0, 0, 0.7);
}

.lightbox .carousel:hover span {
  background: #fff;
}

.lightbox .carousel span {
  width: 10px;
  height: 3px;
  position: absolute;
  left: calc(50% - 5px);
  top: calc(50% - 1.5px);
  display: block;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  /* z-index: -1; */
}

.lightbox .carousel.left {
  left: 5px;
}

.lightbox .carousel.right {
  right: 0;
  right: 5px;
}

.lightbox .carousel.left span:nth-child(1) {
  transform: rotate(-30deg);
  transform-origin: left;
  /* left: 5px; */
}

.lightbox .carousel.left span:nth-child(2) {
  transform: rotate(30deg);
  transform-origin: left;
}

.lightbox .carousel.right span:nth-child(1) {
  transform: rotate(-30deg);
  transform-origin: right;
  right: 5px;
}

.lightbox .carousel.right span:nth-child(2) {
  transform: rotate(30deg);
  transform-origin: right;
}

.lightbox .close {
  background: rgb(255, 255, 255, 0.5);
  height: 20px;
  width: 60px;
  text-align: center;
  margin-top: 100px;
  border-radius: 20px;
  line-height: 20px;
  color: #000;
  transition: 0.5s;
  z-index: 6;
  cursor: pointer;
}

.lightbox .close:hover {
  background: rgba(255, 0, 0, 0.7);
  color: #fff;
}

.lightbox img {
  position: absolute;
  width: 70%;
  height: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  border: 1px solid white;
}

@media only screen and (max-width: 864px) {
  .container {
    grid-template-columns: auto auto;
    /* border: 1px solid green; */
  }
  .lightbox img {
    width: 70%;
    height: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    grid-template-columns: auto;
  }
  .lightbox img {
    width: 70%;
    height: 25%;
  }
  .container .card {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 25px;
    height: 300px;
    overflow: hidden;
    width:90%;
    border-radius: 25px;
    /* width: calc(30% - 7px); */
  }
}

/* @media only screen and (max-width: 1024px) {
  .container .card {
    width: 45%;
  }
  .lightbox img {
    width: 70%;
    height: 50%;
  }
}

/* @media only screen and (max-width: 900px) {
  .container .card {
    width: 40%;
  }
  .lightbox img {
    width: 70%;
    height: 45%;
  }
} */

@media only screen and (max-width: 500px) {
  .lightbox img {
    width: 70%;
    height: 25%;
  }
  .lightbox .close {
    margin-top: 450px;
  }
  .bg-gallery {
    width: 100%;
  }
  .container .card {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 15px auto;
    height: 200px;
    width: 80%;
    overflow: hidden;
    border-radius: 25px;
    /* border:1px solid red */
    /* width: calc(30% - 7px); */
  }
} 

@media only screen and (max-width: 450px) {
  .container .card {
    width: 70%;
  }
} 

@media only screen and (max-width: 320px) {
  .container .card {
    width: 80%;
  }
  .lightbox img {
    height: 20%;
  }
}

.bg-gallery {
  height: 600px;
  /* padding-top: 150px; */
  background-color: #204379;
  box-shadow: 0px 0px 1px 0px #508fb9;
  border: 1px solid white;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.artGallery {
  width:50%;
  height: 300px;
  margin-left: 45%;
  /* margin: 0 auto; */
  /* border: 1px solid red; */
  text-align: end;
  /* justify-content: end;
  align-items: end; */
  /* text-align: end; */
}
.bg-gallery h1 {
  text-align:center;
  /* padding-right: 350px; */
  font-size: 60px;
  font-family: 'Barlow', sans-serif;
  color: white;
  text-shadow: 2px 2px #b9004a;
}

.bg-gallery h5 {
  font-size: 30px;
  text-align: center;
  /* padding-right: 280px; */
  color: white;
  text-shadow: 1px 0px #b9004a;
  /* margin-left: 50px; */
}

.bg-gallery p {
 
  font-size: 20px;
  text-align: center;
  /* padding-left: 195px; */
  color: white;
  /* text-shadow: 1px 0px #b9004a; */
  /* font-weight: bold; */
  /* margin-left: 50px; */
}

.gallery-heading-image {
  text-align: center;
  padding: 20px 0px;
}

.gallery-heading-image h1 {
  text-transform: uppercase;
  /* padding: 30px 0px; */
  padding-top: 40px;
}

.gallery-heading-video h1 {
  text-align: center;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 35px;
}

.gallery-video-main {
  display: flex;
  justify-content: space-around;
  width: 71%;
  /* border:1px solid red; */
  margin: auto;
  padding-bottom: 50px;
}

.video-content-one {
  width: 45%;
  /* box-shadow: 1px 1px red; */
}

.video-content-two {
  width: 45%;
}

/* .awssld { */
  /* --content-background-color: #204379; */
  /* #8d89c4 */
  /* #4baae2 */
/* } */

.bgcolor {
  background-color: #204379;
  position: relative;
}

.circle{
  position: absolute;
  border-radius: 50%;
  /* background:red; */
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 0px 0px #508fb9;
}

/* .small{ */
  /* width: 200px; */
  /* height: 210px; */
  /* left: -100px; */
  /* bottom: -100px; */
/* } */

/* .medium{ */
  /* width: 300px; */
  /* height: 300px; */
  /* left: -200px; */
  /* bottom: -200px; */
/* } */

/* .large{ */
  /* width: 400px; */
  /* height: 400px; */
  /* left: -300px; */
  /* bottom: -300px; */
/* } */

/* .xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
} */

/* .xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
} */


@media only screen and (max-width: 1024px) {
  .gallery-video-main {
    width: 93%;
  }
  .bg-gallery {
    height: 600px;
    /* padding-top: 150px; */
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid white;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
}

@media only screen and (max-width: 768px) {
  .gallery-video-main {
    width: 95%;
  }

  .video-common {
    width: 100%;
    height: 184px;
  }
  .circle{
    display: none;
  }
  .bg-gallery {
    height: 65vh;
    padding-top: 60px;
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid white;
    align-items: center;
    justify-content: center;
    border:"1px solid red"
  }
  .artGallery {
    width:90%;
    height: 300px;
    margin-left: 0%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 60px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 30px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 20px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}

@media only screen and (max-width: 500px) {
  .gallery-video-main {
    width: 98%;
  }

  .video-common {
    width: 100%;
    height: 124px;
  }
  .bg-gallery {
    height: 65vh;
    padding-top: 120px;
    padding-right: 50px;
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid #204379;
    /* align-items: center; */
  }
  .artGallery {
    width:95%;
    height: 300px;
    margin-left: 15%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 45px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 28px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 15px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}

@media only screen and (max-width: 440px) {
  .bg-gallery {
    text-align: center;
    padding-top: 100px;
  }
  .gallery-video-main {
    width: 50%;
    margin: auto;
    flex-direction: column;
  }
  .video-content-one,
  .video-content-two {
    width: 100%;
    padding-bottom: 10px;
  }
  .artGallery {
    width:95%;
    height: 300px;
    margin-left: 15%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 45px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 28px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 15px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}

@media only screen and (max-width: 375px) {
  .gallery-video-main {
    width: 65%;
  }
  .bg-gallery {
    height: 60vh;
    padding-top: 100px;
    padding-right: 50px;
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid #204379;
    /* align-items: center; */
  }
  .artGallery {
    width:95%;
    height: 300px;
    margin-left: 15%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 40px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 25px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 14px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}

@media only screen and (max-width: 320px) {
  .gallery-video-main {
    width: 85%;
  }
  .bg-gallery {
    height: 60vh;
    padding-top: 100px;
    padding-right: 30px;
    background-color: #204379;
    box-shadow: 0px 0px 1px 0px #508fb9;
    border: 1px solid #204379;
    /* align-items: center; */
  }
  .artGallery {
    width:95%;
    height: 300px;
    margin-left: 10%;
    /* margin: 0 auto; */
    /* border: 1px solid red; */
    text-align: end;
    /* justify-content: end;
    align-items: end; */
    /* text-align: end; */
  }
  .bg-gallery h1 {
    text-align:center;
    /* padding-right: 350px; */
    font-size: 38px;
    font-family: 'Barlow', sans-serif;
    color: white;
    text-shadow: 2px 2px #b9004a;
  }
  
  .bg-gallery h5 {
    font-size: 25px;
    text-align: center;
    /* padding-right: 280px; */
    color: white;
    text-shadow: 1px 0px #b9004a;
    /* margin-left: 50px; */
  }
  
  .bg-gallery p {
   
    font-size: 15px;
    text-align: center;
    /* padding-left: 195px; */
    color: white;
    /* text-shadow: 1px 0px #b9004a; */
    /* font-weight: bold; */
    /* margin-left: 50px; */
  }
}

.Total_Project {
  position: relative;
  justify-content: center;
  align-items: center;
  background: var(--mainWhite);
  padding-top: 40px;
}
.Total_Project h2 {
  text-align: center;
  font-size: 32px;
  padding: 20px 0px;
}
.Total_Project p {
  text-align: center;
  padding: 1rem;
}

.Project_container {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  align-items: center;
  /* min-height: 600px; */
  margin: 10px auto;
  padding: 0 !important;
}

.containers {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 100%;
  padding: 5px;
  
} 

.containers .servicescard {
  display: flex;
  /* width:400px; */
  flex-direction: column;
  position: relative;
  margin: 25px;
  min-height: 200px;
  overflow: hidden;
  box-shadow: 1px 1px 6px 1px grey;
  border-radius: 20px;
  color: white;

}

.servicescard:nth-child(odd):hover {
  background: linear-gradient(319deg, rgba(32,67,121,1) 8%, rgba(185,0,74,1) 100%);

}
.servicescard:nth-child(even):hover{
  background: linear-gradient(319deg, rgba(2,0,36,1) 0%, rgba(9,57,121,1) 17%, rgba(0,212,255,1) 100%);

}
.containers .servicescard:hover {
  cursor: pointer;
  /* box-shadow:  0px 0px 10px 4px #204379; */
  border-radius: 25px;
  color: white;
}

.servicescard:nth-child(even){
  background: white;
  color: #204379;
  /* box-shadow:  0px 0px 10px 4px #204379 */
  /* background: none; */
}

/* .servicescard:nth-child(5){
  background: linear-gradient(319deg, rgba(32,67,121,1) 8%, rgba(185,0,74,1) 100%) !important;
  color: white !important;

} */

.servicescard:nth-child(odd){
  background: white;
  color: var(--primaryColor);
  /* box-shadow:  0px 0px 10px 4px #204379 */
  /* background: none; */
}

.cardMain {
  padding: 20px;
  text-align: center;

}

.cardContentImg img {
  position: absolute;
  width: 140px;
  left: 49px;
  top: -45px;
}

.cardContentImg h2 {
  position: absolute;
  top: -30px;
  left: 110px;
  color: white;
  font-family: 'EB Garamond', serif;
}

.card--title {
  font-size: 25px;
  padding-top: 0px;
}

.cardInfo {
  width: 70%;
  margin: auto;
  padding-top: 0px;
  font-size: 14px;
}

.containers .servicescard .servicescard--info {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease;
}

.containers .servicescard .servicescard--info h1 {
  color: #fff;
  font-size: 2em;
  text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000;
}

.containers .servicescard .servicescard--info label {
  color: var(--mainColor);
  font-size: medium;
  margin-top: 20px;
  text-shadow: 0 0 1px #000, 0.5px 0.5px 1px #000, 1px 1px 1px #000;
}

.containers .servicescard .servicescard--info button {
  margin-top: 10px;
  border: none;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  background-color: #b9004a;
  padding: 7px 12px;
}

.servicescard--info button {
  cursor: pointer;
}

.containers .servicescard .servicescard--info button:hover {
  background-color: #204379;
}

.containers .servicescard .servicescard--info h1,
.containers .servicescard .servicescard--info label,
.containers .servicescard .servicescard--info button {
  transform: translateX(-400px);
  transition: 0.5s ease;
}

.containers .servicescard:hover .servicescard--info {
  opacity: 1;
}

.containers .servicescard:hover .servicescard--info h1,
.containers .servicescard:hover .servicescard--info label,
.containers .servicescard:hover .servicescard--info button {
  transform: translateX(0px);
  transition: 0.5s ease;
}

.workbtns:nth-child(odd) {
  border-radius: 5px;
  padding: 5px 15px;
  background-color:white;
  color:var(--mainColor);
  border: 1px solid var(--mainColor);
}


@media only screen and (max-width: 864px) {
  .containers {
    grid-template-columns: auto auto;
    /* border: 1px solid green; */
  }
  
}

@media only screen and (max-width: 600px) {
  .containers {
    grid-template-columns: auto;
  }
  
}


@media only screen and (max-width: 500px) {
  .containers .servicescard {
    width: 50%;
  }
  
  .bg-gallery {
    width: 100%;
  }
} 

@media only screen and (max-width: 425px) {
  .containers .servicescard {
    width: 60%;
    /* grid-template-columns: auto auto auto; */
    margin: 25px auto;
  }
} 

@media only screen and (max-width: 320px) {
  .containers .servicescard {
    width: 90%;
    margin: 25px auto;

  }
  
}

@media screen and (max-width: 1300px) {
  .Project_container {
    grid-template-columns: auto auto auto;
    grid-row-gap: 150px;
    padding: 6rem 0;
    
  }
}
@media screen and (max-width: 996px) {
  .Total_Project {
    justify-content: center;
    align-items: center;
    background: #faf7f8;
    padding-top: 20px;
  }
  .Project_container {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 764px) {
  .Project_container {
    grid-template-columns: auto;
  }
}

.servicesContentMain {
    display: flex;
    padding: 60px 0px;
    /* float: left; */
}

.servicesContentLeft {
    width: 50%;
}

.servicesContentLeft img {
    width: 100%;
    height: 100%;
}

.servicesContentRight {
    width: 50%;
    padding: 80px 30px 80px 60px;
    background-color: #204379;
    color: white;
    
} 

.servicesContentRight h2 {
    font-size: 45px;
}

.servicesContentRight p {
    width: 85%;
    font-size: 28px;
    padding-top: 20px;
    text-align: justify;
}
@media screen and (max-width: 1024px) {
    .servicesContentRight {
       
        padding: 80px 30px 80px 30px;
       
    } 
    
    .servicesContentRight h2 {
        font-size: 40px;
    }
    
    .servicesContentRight p {
        width: 85%;
        font-size: 22px;
        padding-top: 20px;
        text-align: justify;
    }
  }
  @media screen and (max-width: 425px) {
    .servicesContentLeft {
        width: 20%;
        height:200px;
        float: left;
        display: none;
    }
    
    .servicesContentLeft img {
        width: 100%;
        height: 100%;
    }
    
    .servicesContentRight {
        width: 100%;
      
        
    } 
    .servicesContentRight h2 {
        font-size: 29px;
    }
    
    .servicesContentRight p {
        width: 93%;
        font-size: 16px;
        padding-top: 20px;
        text-align: justify;
    }
    .servicesContentRight {
       
        padding: 60px 30px 60px 30px;
       
    } 
  }
.projectimg_Total {
    background-color: #204379;
    height: 550px;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    overflow: hidden;
  }
  
  .projectHeading {
    width: 50%;
    padding-top: 100px;
    padding-right: 70px;
    text-align: center;
    color: white;
    
  }
  
  .projectHeading h2 {
    font-size: 50px;
  }
  
  .projectHeading p {
    font-size: 39px;
   
  }
  .projectimgMain {
    position: relative;
    animation: MyDiv1;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
  }
  
  @keyframes MyDiv1 {
    0%   {left: -650px; top: 0px;}
    100%  {left: 0px; top: 0px;}
  }
  
  .projectimage {
    width: 650px;
    border-radius: 50%;
    position: absolute;
    left: -70px;
    top: 30px;
  }
  @media only screen and (max-width: 1024px) {
    .projectimage {
      width: 550px;
      border-radius: 50%;
      position: absolute;
      left: -70px;
      top: 30px;
    }
    .projectHeading h2 {
      font-size: 40px;
    }
    
    .projectHeading p {
      font-size: 28px;
     
    }
  
  }
  @media only screen and (max-width: 768px) {
    .projectimage {
      width: 440px;
      border-radius: 50%;
      position: absolute;
      left: -70px;
      top: 30px;
    }
    .projectHeading h2 {
      font-size: 30px;
    }
    
    .projectHeading p {
      font-size: 20px;
     
    }
    .projectimg_Total {
      background-color: #204379;
      height: 470px;
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      overflow: hidden;
    }
  
  }
  @media only screen and (max-width: 425px) {
    .projectimage {
      width: 270px;
      border-radius: 50%;
      position: absolute;
      left: -70px;
      /* top: 50px; */
    }
    .projectHeading h2 {
      font-size: 18px;
    }
    
    .projectHeading p {
      font-size: 13px;
     
    }
    .projectHeading {
      width: 50%;
      padding-top: 70px;
      padding-right: 10px;
      text-align: center;
      color: white;
      
    }
    .projectimg_Total {
      background-color: #204379;
      height: 350px;
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      overflow: hidden;
    }
  
  }
  @media only screen and (max-width: 375px) {
    .projectimage {
      width: 245px;
      border-radius: 50%;
      position: absolute;
      left: -80px;
      /* top: 50px; */
    }
    .projectHeading h2 {
      font-size: 18px;
    }
    
    .projectHeading p {
      font-size: 13px;
     
    }
    .projectHeading {
      width: 50%;
      padding-top: 60px;
      padding-right: 10px;
      text-align: center;
      color: white;
      
    }
    .projectimg_Total {
      background-color: #204379;
      height: 350px;
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      overflow: hidden;
    }
  
  }

    
  
.contact-header {
  background-image: linear-gradient(rgb(12, 12, 12, 0.5), rgba(7, 7, 7, 0.205)),
    url(/static/media/img9.7d99215e.jpg);
  height: 530px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-block {
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  /* background: #df2707; */
  background-image: url(/static/media/background3.c860452d.jpg);
  background-size: cover;
  height: 600px;
  padding: 1rem;
}
.contact-details {
  position: absolute;
  top: -23%;
  left: 16%;
  height: 450px;
  width: 400px;
  background-color:var(--primaryColor);
  color: var(--mainWhite);
  padding: 1rem;
  text-align: center;
  box-shadow: rgba(45, 80, 130, 0.698) 0px 0px 2px;
  z-index: 1;
  border-radius: 15px;
}
.contact-details h2 {
  color: #00ffd1;
  text-transform: capitalize;
  padding: 3rem 0 2rem 0;
}
.contact-info div {
  padding: 1rem 0;
}
.socialmedia {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  padding:1rem;

}
.socialmedia :hover{
  color: var(--mainColor);
}
.contact-details a {
  color: var(--mainWhite);
}
.contact-form {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: -25%;
  left: 30%;
  height: auto;
  width: 800px;
  padding: 2rem;
  border-radius: 15px;
  background: white;
  box-shadow: rgba(45, 80, 130, 0.698) 0px 0px 2px;
}
.contact-form > div {
  padding: 0 2rem;
  width: 70%;
  /* border: 1px solid red; */
}
.contact-form h2 {
  padding: 1rem 2rem;
  text-align: center;
}
.contact-form p {
  padding-top: 1rem;
}
.contact-form form {
  margin-top: 1rem;
  height: 75%;
  /* border: 1px solid green; */
}
.form-input {
  background: none;
  /* background-color: var(--lightGray); */
  color: var(--paraGraphs);
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  /* border: none; */
  border-radius: 2px;
  border:1px solid #c8dcfa;
  /* border-bottom: 2px solid #3498db; */
  margin: 1rem 0;
}
.form-input:focus {
  outline: none;
}

.contact-form .btns {
  color: var(--mainWhite);
  cursor: pointer;
  text-align: center;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  font-size: 20px;
  border-radius: 10px;
  padding: 5px;
}


@media only screen and (max-width: 1200px) {
  .contact-details {
    left: 5%;
  }
  .contact-form {
    left: 18%;
  }
}

@media only screen and (max-width: 768px) {
  .contact-details {
    position: relative;
    left: 0;
    width: 100%;
    height: 150px;
  }
  .hidden-content {
    display: none;
  }
  .contact-form {
    position: relative;
    left: 0;
    justify-content: center;
     width: 100%;
  }
}

@media only screen and (max-width: 864px) {
  .contact-block {
    padding: 0;
  }
  .contact-form {
    width: 100%;
    padding: 2rem 0;
  }
  .contact-form > div {
    padding: 0 2rem;
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .contact-block {
    position: relative;
    /* display: flex; */
    /* flex-direction: column; */
    /* background: #df2707; */
    background-image: url(/static/media/background3.c860452d.jpg);
    background-size: cover;
    height: 110vh;
    padding: 0;
  }
  .contact-form {
    width: 100%;
    padding: 2rem 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .contact-form > div {
    padding: 0 2rem;
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .contact-block {
    position: relative;
    /* display: flex; */
    /* flex-direction: column; */
    /* background: #df2707; */
    background-image: url(/static/media/background3.c860452d.jpg);
    background-size: cover;
    height: 120vh;
    padding: 0;
  }
  .contact-form {
    width: 100%;
    padding: 2rem 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .contact-form > div {
    padding: 0 2rem;
    width: 100%;
  }
}


nav {
  color: var(--primaryColor);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--mainWhite);
  z-index: 1002;
  box-shadow: rgba(45, 80, 130, 0.698) 0px 0px 2px;
}
nav > div {
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 80px;
  margin: 0 auto;
}
.top-nav {
  text-decoration: none;
  list-style-type: none;
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.top-nav a {
  font-family: 'Roboto', sans-serif;
  color: var(--primaryColor);
  text-transform: capitalize;
  padding-bottom: 0.5rem;
  transition: transform 0.8s ease-in-out;
  /* border: 1px solid red; */
}

.top-nav a:hover {
  color: var(--primaryColor);
  border-bottom: 2px solid var(--primaryColor);
}

@media only screen and (max-width: 996px) {
  .top-nav {
    width: 60%;
  }
}

@media only screen and (max-width: 764px) {
  .top-nav {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  
    nav {
      color: var(--primaryColor);
      position: -webkit-sticky;
      position: sticky;
      top: 50px;
      background: var(--mainWhite);
      z-index: 1002;
      box-shadow: rgba(45, 80, 130, 0.698) 0px 0px 2px;
    }
  
}

.mobile-menu-screen {
  display: none;
}
.top-nav-mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
}

.top-nav-mobile ul {
  position: relative;
  display: flex;
  flex-direction: column;
}

.top-nav-mobile ul li a {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  font-size: 2em;
  text-decoration: none;
  color: var(--mainWhite);
  /* transition: 1s; */
  /* border-bottom: 4px solid var(--mainColor); */
}
.cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--mainGold);
  transition: 0.1s;
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
}

/* .top-nav-mobile ul li  */
/* .top-nav-mobile ul li a:hover {
  color: var(--mainGold);
} */

.top-nav-mobile ul li:hover ~ .cursor {
  transform: scale(5);
}

@media only screen and (max-width: 764px) {
  .mobile-menu-screen {
    display: block;
  }
}

header {
  color: var(--mainWhite);
  background:var(--primaryColor);
  height: 50px;
  padding-top: 5px;
}
header > div {
  display: flex;
  width: 80%;
  justify-content: space-between;
  /* border: 1px solid green; */
  margin: 0 auto;
  padding: 0.5rem 0;
}
.top_nav-details {
  display: flex;
  justify-content: space-between;
  width: 40%;
  /* border: 1px solid yellow; */
}
.top_nav-socials {
  /* border: 1px solid white; */
  width: 20%;
}

header a {
  color: white;
}
.top_nav-details a {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1400px) {
  .top_nav-details {
    width: 40%;
    /* border: 1px solid white; */
  }
}
@media only screen and (max-width: 1200px) {
  .top_nav-details {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .top_nav-socials {
    display: none;
  }
  .top_nav-details {
    width: 100%;
    padding:0 10px ;
  }
}

@media only screen and (max-width: 500px) {
  header > div {
    width: 100%;
  }
  header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1002;
  }

}

.singleProject {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  width: 80%;
  margin: 0 auto;
  padding: 4rem 0;
  min-height: 80vh;
}

.singleProject .singleProject-img {
  display: flex;
  justify-content: center;
  background: var(--mainColor);
}
.singleProject-content {
  color: #fff;
  padding: 2rem;
  background: var(--mainColor);
  text-align: justify;
}
.singleProject-content h2 {
  padding: 1rem 0;
}
.singleProject-content p {
  padding: 0.5rem 0;
}

@media only screen and (max-width: 1200px) {
  .singleProject {
    grid-template-columns: auto;
  }
}
@media only screen and (max-width: 764px) {
  .singleProject {
    width: 100%;
  }
  .singleProject .carousel .slider-wrapper {
    width: 80%;
  }
  .singleProject .singleProject-img,
  .singleProject-content {
    margin: 0 auto;
    width: 80%;
  }
  .singleProject .carousel-root {
    height: 300px;
    width: 80%;
  }
}

.footer-main {
  background:var(--primaryColor);
  position: relative;
  width:100%;
  min-height: 250px;
  /* margin-top: 75px; */
  /* border:1px solid #0b3a80; */
  
}
.lastText{
  text-align: justify;
}

.footer-inner {
  display: grid;
  grid-template-columns: 20% 20% 25% 30%;
  grid-column-gap: 1rem;
  width: 85%;
  margin: 0 auto;
  text-align:start;
  justify-content: center;
  /* border:1px solid white; */
  /* font-size: 85%; */
  /* padding-bottom: 20px; */
  padding: 50px 60px 20px 50px;
}

.div1{
  width: 100%;
  padding-right: 100px;
}
.socialmedia_icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 60%;
  margin: 0;
  padding-bottom: 20px;
}

.footer-inner .socialmedia_icons {
  padding-top: 1rem;
}

ul,
li {
  list-style-type: none;
}

.footer-inner p {
  padding-bottom: 1rem;
  color:#d4d3d3;
  /* border:1px solid red; */
}

.footer-inner ul li {
  padding: 0.5rem;
}
.footer-inner a {
  text-decoration: none;
  color: #d4d3d3;
  /* border:1px solid black */
}
.footer-inner a:hover {
  color:var(--mainColor);
  cursor: pointer;
}

.footer-main .wave{
  /* border:none !important; */
  position: absolute;
  top: -200px;
  left:0;
  width: 100%;
  height: 200px;
  background: url(/static/media/wave.b8c6dc99.svg);
  background-size: 1000px;
  /* border:none */
}
#wave1{
  /* border:none !important; */
  z-index:1000;
  opacity:1;
  bottom:0;
  animation: animateWave 4s linear infinite;
}

#wave2{
  /* border:none !important; */
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave_02 4s linear infinite;
}
#wave3{
  /* border:none !important; */

  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWave_03 3s linear infinite;
}
#wave4{
  /* border:none !important; */
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave_04 3s linear infinite;
}

@keyframes animateWave{
  0%{
    background-position-x: 1000px;
  }
  100%{
    background-position-x: 0px;
  }
}
@keyframes animateWave_02{
  0%{
    background-position-x: 0px;
  }
  100%{
    background-position-x: 1000px;
  }
}
@keyframes animateWave_03{
  0%{
    background-position-x: 1000px;
  }
  100%{
    background-position-x: 0px;
  }
}
@keyframes animateWave_04{
  0%{
    background-position-x: 0px;
  }
  100%{
    background-position-x: 1000px;
  }
}
.copyRights{
  text-align: center;
  color: #d4d3d3;
  display: block;
  padding-top: 10px;
  border-top: 1px solid #1352b0;
  padding-bottom: 20px;
 /* border:1px solid red */
}

@media only screen and (max-width: 1024px) {

  .footer-inner {
    display: grid;
    grid-template-columns: 20% 20% 30% 30%;
    grid-column-gap: 1rem;
    width: 90%;
    margin: 0 auto;
    text-align:start;
    /* padding-bottom: 20px; */
    padding: 50px 60px 20px 50px;
  }
  
  .copyRights {
    flex-direction: column;
  }
  .socialmedia_icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .div1{
    width: 100%;
    margin: 0 auto;
    padding-right:0px;
    padding-bottom: 10px;
  }

}
@media only screen and (max-width: 996px) {
  .footer-inner {
    grid-template-columns: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .copyRights {
    flex-direction: column;
  }
  .socialmedia_icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .div1{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding-right:0px;
    padding-bottom: 10px;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .lastText{
    text-align: center;
    justify-content: center;
    align-items: center;
  }

}
@media only screen and (max-width: 768px) {
  .footer-inner {
    grid-template-columns: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .copyRights {
    flex-direction: column;
  }
  .socialmedia_icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .div1{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding-right:0px;
    padding-bottom: 10px;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
    text-align: center;
  }

}
@media only screen and (max-width: 425px) {
  .footer-inner {
    grid-template-columns: 100%;
    text-align: center;
    align-items: center;
  }
  .copyRights {
    flex-direction: column;
  }
  .socialmedia_icons {
   
    display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
  }
  .div1{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding-right:0px;
    padding-bottom: 10px;
  }

}



/* font family */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'EB Garamond', serif, 'Quicksand', sans-serif;
  /* font-family: 'Poppins', sans-serif; */
}
html {
  scroll-behavior: smooth;
}

:root {
  --mainColor: #02b595;
  --mainWhite: #fff;
  --mainBlack: #000;
  --paraGraphs: #2d2d2d;
  --headings: #202020;
  --grayBack: #757575;
  --lightGray: #e6e1e1;
  --mainGold: gold;
  --primaryColor:#00396E;
}

/* loader */
.loader_bg {
  position: fixed;
  z-index: 9999999;
  background: #fff;
  width: 100%;
  height: 100%;
}
.loader {
  border: 0 solid transparent;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: absolute;
  top: calc(50vh - 75px);
  left: calc(49vw - 75px);
}
.loader:before,
.loader:after {
  content: '';
  /* border: 1em solid #ea174a; */
  background: url(/static/media/QJM.dad5aeb0.png);
  background-size: 100%;
  background-repeat: no-repeat;
  /* border-radius: 50%; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* animation: loader 2s linear infinite; */
  /* opacity: 0; */
}
/* .loader:before {
  animation-delay: 0.5s;
} */
/* @keyframes loader {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
} */
/* loaders */

.btn {
  padding: 0.2rem;
  border-radius: 2px;
  color: #fff;
  color: var(--mainWhite);
  background: #02b595;
  background: var(--mainColor);
}

