.projectimg_Total {
    background-color: #204379;
    height: 550px;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    overflow: hidden;
  }
  
  .projectHeading {
    width: 50%;
    padding-top: 100px;
    padding-right: 70px;
    text-align: center;
    color: white;
    
  }
  
  .projectHeading h2 {
    font-size: 50px;
  }
  
  .projectHeading p {
    font-size: 39px;
   
  }
  .projectimgMain {
    position: relative;
    animation: MyDiv1;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
  }
  
  @keyframes MyDiv1 {
    0%   {left: -650px; top: 0px;}
    100%  {left: 0px; top: 0px;}
  }
  
  .projectimage {
    width: 650px;
    border-radius: 50%;
    position: absolute;
    left: -70px;
    top: 30px;
  }
  @media only screen and (max-width: 1024px) {
    .projectimage {
      width: 550px;
      border-radius: 50%;
      position: absolute;
      left: -70px;
      top: 30px;
    }
    .projectHeading h2 {
      font-size: 40px;
    }
    
    .projectHeading p {
      font-size: 28px;
     
    }
  
  }
  @media only screen and (max-width: 768px) {
    .projectimage {
      width: 440px;
      border-radius: 50%;
      position: absolute;
      left: -70px;
      top: 30px;
    }
    .projectHeading h2 {
      font-size: 30px;
    }
    
    .projectHeading p {
      font-size: 20px;
     
    }
    .projectimg_Total {
      background-color: #204379;
      height: 470px;
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      overflow: hidden;
    }
  
  }
  @media only screen and (max-width: 425px) {
    .projectimage {
      width: 270px;
      border-radius: 50%;
      position: absolute;
      left: -70px;
      /* top: 50px; */
    }
    .projectHeading h2 {
      font-size: 18px;
    }
    
    .projectHeading p {
      font-size: 13px;
     
    }
    .projectHeading {
      width: 50%;
      padding-top: 70px;
      padding-right: 10px;
      text-align: center;
      color: white;
      
    }
    .projectimg_Total {
      background-color: #204379;
      height: 350px;
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      overflow: hidden;
    }
  
  }
  @media only screen and (max-width: 375px) {
    .projectimage {
      width: 245px;
      border-radius: 50%;
      position: absolute;
      left: -80px;
      /* top: 50px; */
    }
    .projectHeading h2 {
      font-size: 18px;
    }
    
    .projectHeading p {
      font-size: 13px;
     
    }
    .projectHeading {
      width: 50%;
      padding-top: 60px;
      padding-right: 10px;
      text-align: center;
      color: white;
      
    }
    .projectimg_Total {
      background-color: #204379;
      height: 350px;
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      overflow: hidden;
    }
  
  }

    
  