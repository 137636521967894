.Backimg_Total {
 
  display: flex;
  width: 100%;
  background-color: var(--primaryColor);
  justify-content: space-between;
  /* align-items: center; */
  padding-top: 60px;
  height: 680px;
  overflow: hidden;
  /* padding-left: 100px; */
}

.aboutHeading {
  width: 40%;
  /* padding-top: 100px; */
  padding-left: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  
}

.aboutHeading h2 {
  font-size: 50px;
}

.aboutHeading p {
  font-size: 35px;
 
}
.aboutimgMain {
  position: relative;
  animation: MyDiv;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

@keyframes MyDiv {
  0%   {right: -650px; top: 0px;}
  100%  {right: 0px; top: 0px;}
}

.aboutimage {
  width: 680px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  /* top: 70px; */
}

@media only screen and (max-width: 1024px) {
  .aboutimage {
    width: 580px;
    border-radius: 50%;
    position: absolute;
    right: -70px;
    /* top: 70px; */
  }
  .Backimg_Total {
    height: 550px;
    overflow-x: hidden;
    width:100%;
    padding-left: 0px;
    padding-top: 0px;
  }
  .aboutHeading h2 {
    font-size: 40px;
  }
  
  .aboutHeading p {
    font-size: 28px;
   
  }

}
@media only screen and (max-width: 768px) {
  
  .aboutimage {
    width: 450px;
    border-radius: 50%;
    position: absolute;
    right: -70px;
    /* top: 70px; */
  }
  .aboutHeading h2 {
    font-size: 30px;
  }
  
  .aboutHeading p {
    font-size: 18px;
   
  }
  .Backimg_Total {
    height: 450px;
    width: 100%;
    overflow: hidden;

   
  }

}
@media only screen and (max-width: 425px) {
  .aboutimage {
    width: 250px;
    border-radius: 50%;
    position: absolute;
    right: -70px;
    top: 70px;
  }
  .aboutHeading h2 {
    font-size: 20px;
  }
  
  .aboutHeading p {
    font-size: 12px;
   
  }
  .Backimg_Total {
    height: 350px;
    overflow: hidden;
    padding-top: 0px;
    width: 100%;
  }
  .aboutHeading {
    width: 50%;
    /* padding-top: 100px; */
    padding-left: 0px;
    text-align: center;
    color: white;
    
  }

}

