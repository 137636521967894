.mobile-menu-screen {
  display: none;
}
.top-nav-mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
}

.top-nav-mobile ul {
  position: relative;
  display: flex;
  flex-direction: column;
}

.top-nav-mobile ul li a {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  font-size: 2em;
  text-decoration: none;
  color: var(--mainWhite);
  /* transition: 1s; */
  /* border-bottom: 4px solid var(--mainColor); */
}
.cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--mainGold);
  transition: 0.1s;
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
}

/* .top-nav-mobile ul li  */
/* .top-nav-mobile ul li a:hover {
  color: var(--mainGold);
} */

.top-nav-mobile ul li:hover ~ .cursor {
  transform: scale(5);
}

@media only screen and (max-width: 764px) {
  .mobile-menu-screen {
    display: block;
  }
}
