.singleProject {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  width: 80%;
  margin: 0 auto;
  padding: 4rem 0;
  min-height: 80vh;
}

.singleProject .singleProject-img {
  display: flex;
  justify-content: center;
  background: var(--mainColor);
}
.singleProject-content {
  color: #fff;
  padding: 2rem;
  background: var(--mainColor);
  text-align: justify;
}
.singleProject-content h2 {
  padding: 1rem 0;
}
.singleProject-content p {
  padding: 0.5rem 0;
}

@media only screen and (max-width: 1200px) {
  .singleProject {
    grid-template-columns: auto;
  }
}
@media only screen and (max-width: 764px) {
  .singleProject {
    width: 100%;
  }
  .singleProject .carousel .slider-wrapper {
    width: 80%;
  }
  .singleProject .singleProject-img,
  .singleProject-content {
    margin: 0 auto;
    width: 80%;
  }
  .singleProject .carousel-root {
    height: 300px;
    width: 80%;
  }
}
